import React, { useContext } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@achieve/sunbeam";
import { ContainerMap } from "constants/mappings";
import { ListDataContext } from "contexts/ListDataContext/ListDataContext";
import { ListEditorProps } from "ui/ListEditor/ListEditor.interface";
import { sortIdsByName } from "utilities/sort";

const ListEditor: React.FC<ListEditorProps> = ({
  dictionary,
  selection,
  origin,
  handleToggle,
  getBgColor,
  selectionChecked,
  originChecked,
  handleCheckedSelection,
  handleCheckedOrigin,
}) => {
  const {
    container: { type: containerType },
    listInEdit: { title, hideContainerName },
  } = useContext(ListDataContext);

  const customList = (items: readonly string[], listId: string) => (
    <Paper
      sx={{
        height: "fit-content",
        minHeight: "50px",
        maxHeight: "200px",
        width: "100%",
        overflow: "auto",
      }}
    >
      <List dense component="div" role="list" data-testid={`${listId}-list`}>
        {items.length === 0 && listId === "selection" && (
          <ListItem
            sx={{ fontStyle: "italic", color: "lightgray" }}
            data-testid="placeholder"
          >
            Add items from the list below
          </ListItem>
        )}
        {items.length > 0 &&
          sortIdsByName(items, dictionary).map((value: string) => (
            <ListItemButton
              key={value}
              onClick={() => handleToggle(items, value)}
              sx={{
                backgroundColor: getBgColor(value),
              }}
            >
              <ListItemText
                id={value}
                primary={<Typography>{dictionary[value]}</Typography>}
              />
            </ListItemButton>
          ))}
      </List>
    </Paper>
  );

  return (
    <Stack spacing={2} marginX={2} justifyContent="center" alignItems="center">
      <Typography alignSelf="start" variant={"bodyS20"}>
        {title}s{" "}
        {ContainerMap[containerType] && !hideContainerName
          ? `this ${ContainerMap[containerType]}`
          : ""}
      </Typography>
      {customList(selection, "selection")}
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          sx={{ my: 0.5 }}
          size="small"
          onClick={handleCheckedOrigin}
          disabled={originChecked.length === 0}
          aria-label="move to selection"
        >
          <ArrowUp />
        </Button>
        <Button
          sx={{ my: 0.5 }}
          size="small"
          onClick={handleCheckedSelection}
          disabled={selectionChecked.length === 0}
          aria-label="move to origin"
        >
          <ArrowDown />
        </Button>
      </Stack>
      {customList(origin, "origin")}
    </Stack>
  );
};
export default ListEditor;
