import {
  MortgageAdvisor,
  OperationsPersonnel,
  OperationsPersonnelInPod,
  OperationsType,
  Pod,
  UpdatePodPayload,
} from "@sindeo/rest-services";
import { groupBy, has, set } from "lodash";
import { SingleList } from "ui/ListBox/ListBox.interface";

export const processMortgageAdvisorsInPod = (
  mortgageAdvisors: MortgageAdvisor[] | undefined
) =>
  (mortgageAdvisors ?? []).reduce(
    (acc, { mortgage_advisor_id, first_name, last_name }) =>
      set(acc, mortgage_advisor_id, `${first_name} ${last_name}`),
    {}
  );

export const processOperationsPersonnelInPod = (
  personnel: OperationsPersonnelInPod[]
) =>
  (personnel ?? []).reduce(
    (acc, { operations_personnel_type_id, name }) =>
      set(acc, operations_personnel_type_id, name),
    {}
  );

export const processPod = (
  pod: Pod | undefined,
  operationsTypes: OperationsType[] | undefined
) => {
  if (!pod || !operationsTypes?.length) {
    return [];
  }

  const { mortgage_advisors, operations_personnel } = pod;

  const lists: SingleList[] = [
    {
      title: "Mortgage Advisor",
      list_identifier: "mortgage_advisors",
      item_identifier: "mortgage_advisor_id",
      items: processMortgageAdvisorsInPod(mortgage_advisors),
    },
  ];

  const operations = groupBy(
    operations_personnel,
    ({ operations_type }) => operations_type
  );

  const operationsLists = operationsTypes.map(
    ({ type_name, operations_type_code }) => {
      return {
        title: type_name,
        list_identifier: operations_type_code,
        item_identifier: "operations_personnel_type_id",
        items: processOperationsPersonnelInPod(
          operations[operations_type_code]
        ),
      };
    }
  );

  return lists.concat(operationsLists);
};

// this method iterates over operations personnel and sorts them into buckets by type (operations_type_code)
// since one person can have multiple roles (operations_types), he/she can end up in more than one bucket
export const processOperationsPersonnel = (
  personnel: OperationsPersonnel[] | undefined
) =>
  (personnel ?? []).reduce((acc, { name, operations_types }) => {
    operations_types.forEach(
      ({ operations_type_code, operations_personnel_type_id }) => {
        if (has(acc, operations_type_code)) {
          set(acc[operations_type_code], operations_personnel_type_id, name);
        } else {
          set(acc, operations_type_code, {
            [operations_personnel_type_id]: name,
          });
        }
      }
    );

    return acc;
  }, {} as Record<string, Record<string, string>>);

export const isMortgageAdvisorAssigned = (id: string, pods: Pod[]) =>
  pods.some(({ mortgage_advisors }) =>
    mortgage_advisors.some(
      ({ mortgage_advisor_id }) => mortgage_advisor_id === id
    )
  );

export const processMortgageAdvisors = (
  mortgageAdvisors: MortgageAdvisor[] | undefined,
  pods: Pod[] | undefined
) => {
  if (!mortgageAdvisors?.length || !pods?.length) {
    return {};
  }
  return mortgageAdvisors.reduce(
    (acc, { mortgage_advisor_id, first_name, last_name }) => {
      if (!isMortgageAdvisorAssigned(mortgage_advisor_id, pods)) {
        set(acc, mortgage_advisor_id, `${first_name} ${last_name}`);
      }
      return acc;
    },
    {}
  );
};

export const buildPodPayload = (
  selectedPod: Pod | undefined,
  list: SingleList,
  values: string[]
) => {
  const { list_identifier, item_identifier } = list;

  if (list_identifier === "mortgage_advisors") {
    return {
      name: selectedPod?.name ?? "",
      mortgage_advisors: values.map((value) => ({
        [item_identifier as string]: value,
      })),
    } as UpdatePodPayload;
  }

  return {
    name: selectedPod?.name ?? "",
    operations_personnel: {
      operations_type_code: list_identifier,
      operations_personnel_type_ids: [...values],
    },
  } as UpdatePodPayload;
};
