import { ControlledSelectOption } from "@sindeo/react-forms";
import { NAME_SCHEMA } from "ui/AddEditModal/AddEditModal";
import * as Yup from "yup";

export const ROLE_SCHEMA = (nameOptions: ControlledSelectOption[]) =>
  Yup.object(NAME_SCHEMA(nameOptions.map(({ label }) => label))).shape({
    operations_type_code: Yup.string().required(
      "Role Abbreviation is required"
    ),
  });
