import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Button, Link, Menu, MenuItem } from "@achieve/sunbeam";
import { kebabCase } from "lodash";
import { MenuButtonProps } from "ui/MenuButton/MenuButton.interface";

const MenuButton: React.FC<MenuButtonProps> = ({ title, path, options }) => {
  const kebabCaseTitle = kebabCase(title);
  const buttonId = `${kebabCaseTitle}-button`;
  const menuId = `${kebabCaseTitle}-menu`;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const buttonStyleProps = {
    backgroundColor: "white",
    padding: "20px 30px !important",
    width: 250,
  };

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!path && !options) {
    throw Error("No path or options provided for menu button " + title);
  }

  return path ? (
    <Link href={path} underline={"none"}>
      <Button id={buttonId} variant={"text"} sx={{ ...buttonStyleProps }}>
        {title}
      </Button>
    </Link>
  ) : (
    <>
      <Button
        id={buttonId}
        variant={"text"}
        sx={{ ...buttonStyleProps }}
        endIcon={open ? <ChevronUp /> : <ChevronDown />}
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        {options?.map(({ title, path }) => (
          <MenuItem key={kebabCase(title)} onClick={handleClose}>
            <Link href={path} underline={"none"} width={"100%"}>
              {title}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
