import React, { useCallback, useState } from "react";

export const useAddEditModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setIsEditMode(false);
    setIsModalOpen(false);
  }, []);

  const openModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsEditMode((event.target as HTMLButtonElement).textContent === "Edit");
      setIsModalOpen(true);
    },
    []
  );

  const toggleEditMode = useCallback(() => setIsEditMode((prev) => !prev), []);

  return {
    isModalOpen,
    isEditMode,
    toggleEditMode,
    openModal,
    closeModal,
  };
};
