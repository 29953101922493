import { GroupTeamMapping, LeadSourceGroup } from "@sindeo/rest-services";
import { set } from "lodash";

export const formatRank = (rank: number | undefined) =>
  // rank can be undefined; 0 is also considered invalid value
  rank
    ? rank.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
    : "--";

export const formatSpillOverTime = (time: number | undefined) =>
  // time can be undefined; 0 is also considered invalid value
  time ? `${time}m` : "--";

export const processLeadGroup = (
  group: LeadSourceGroup | undefined,
  mappings: GroupTeamMapping[] | undefined
) => {
  if (!group || !mappings?.length) {
    return [];
  }

  const groupMapping = mappings.find(
    ({ groupName }) => groupName === group.groupName
  );

  return [
    {
      title: "Lead Source",
      list_identifier: "sources",
      items: (group.sources ?? []).reduce(
        (acc, source) => set(acc, source, source),
        {}
      ),
    },
    {
      title: "MA Team",
      list_identifier: "teams",
      items: (groupMapping?.teamNames ?? []).reduce(
        (acc, name) => set(acc, name, name),
        {}
      ),
    },
  ];
};
