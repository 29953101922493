import React, { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlledInput } from "@sindeo/react-forms";
import { AddEditModalProps } from "ui/AddEditModal/AddEditModal.interface";
import * as Yup from "yup";
import { AnyObject, StringSchema } from "yup";

export const NAME_SCHEMA: (existingNames: string[]) => {
  name: StringSchema<string | undefined, AnyObject, undefined, "">;
} = (existingNames) => ({
  name: Yup.string()
    .required("Name is required")
    .test({
      name: "name",
      test: (value: string) =>
        !existingNames.length || !existingNames.includes(value),
      message: "Name already exists",
    }),
});

const AddEditModal: React.FC<AddEditModalProps> = ({
  title,
  inputLabel = "Name",
  existingNames = [],
  open,
  onClose,
  onSave,
  children: customForm,
  defaultValues = { name: "" },
  schema = Yup.object(NAME_SCHEMA(existingNames)),
}) => {
  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit: registerHandleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const isFormInvalid = useMemo(() => !isValid, [isValid]);
  const isFormUntouched = useMemo(() => !isDirty, [isDirty]);

  const handleSubmit = useCallback(
    async (values: Record<string, any>) => {
      onSave(values);
    },
    [onSave]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ alignSelf: "start" }}>{title}</DialogTitle>
      <DialogContent sx={{ border: "none !important" }}>
        <FormProvider {...formMethods}>
          <form id="inputForm" onSubmit={registerHandleSubmit(handleSubmit)}>
            {customForm ?? <ControlledInput label={inputLabel} name="name" />}
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions
        sx={{ flexDirection: "column", alignItems: "stretch !important" }}
      >
        <Button
          form="inputForm"
          type="submit"
          disabled={isFormInvalid || isFormUntouched}
        >
          Save
        </Button>
        <Button
          type="reset"
          variant={"text"}
          sx={{ m: "16px 0 !important" }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditModal;
