import React, { useMemo, useState } from "react";
import { noop } from "lodash";

import {
  GlobalComponentContextProviderProps,
  GlobalComponentContextValue,
} from "./GlobalComponentContext.interface";

const GlobalComponentContextValueDefault: GlobalComponentContextValue = {
  onExitCallback: noop,
  setOnExitCallback: noop,
  showExitConfirmation: false,
  setShowExitConfirmation: noop,
  exitConfirmationMessage: undefined,
  setExitConfirmationMessage: noop,
};

export const GlobalComponentContext = React.createContext(
  GlobalComponentContextValueDefault
);
GlobalComponentContext.displayName = "GlobalComponentContext";

const GlobalComponentContextProvider: React.FC<
  GlobalComponentContextProviderProps
> = ({ children }) => {
  const [exitCallback, setExitCallback] = useState<any>();

  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  const [exitConfirmationMessage, setExitConfirmationMessage] = useState<
    string | undefined
  >();

  const value = useMemo(
    () => ({
      setOnExitCallback: setExitCallback,
      onExitCallback: exitCallback,
      showExitConfirmation,
      setShowExitConfirmation,
      exitConfirmationMessage,
      setExitConfirmationMessage,
    }),
    [exitCallback, exitConfirmationMessage, showExitConfirmation]
  );

  return (
    <GlobalComponentContext.Provider value={value}>
      {children}
    </GlobalComponentContext.Provider>
  );
};

export default GlobalComponentContextProvider;
