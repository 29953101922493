import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import { ControlledSelectOption } from "@sindeo/react-forms";
import { CreatePodPayload, Pod, UpdatePodPayload } from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";
import { sortByField } from "utilities/sort";

export const usePods = () => {
  const queryKey = ["pods"];
  const queryClient = useQueryClient();

  const [selectedPodId, setSelectedPodId] = useState<string>("");

  const {
    data: pods,
    isLoading: isPodsLoading,
    error: isPodsError,
  } = useQuery<Pod[], string>({
    queryKey,
    queryFn: () => Rest.ops.getPods(),
  });

  const { mutateAsync: createPod, isLoading: isCreatePodLoading } = useMutation(
    (pod: Partial<Pod>) =>
      Rest.ops.createPod({ data: [pod] } as CreatePodPayload),
    {
      onSuccess: async (data) => {
        if (data?.length) {
          setSelectedPodId(data[0].pod_id);
        }
        await queryClient.invalidateQueries({ queryKey });
      },
      onError: (error) => {
        logger.error("Error while creating a pod", error);
      },
    }
  );

  const { mutateAsync: updatePod, isLoading: isUpdatePodLoading } = useMutation(
    (pod: UpdatePodPayload) => Rest.ops.updatePod(selectedPodId, pod),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
      onError: (error) => {
        logger.error(
          "Error while updating a pod with id ",
          selectedPodId,
          error
        );
      },
    }
  );

  const podsOptions = useMemo(() => {
    const options =
      pods?.map(({ name, pod_id }: Pod) => ({
        label: name,
        value: pod_id,
      })) ?? [];
    return sortByField<ControlledSelectOption>(options);
  }, [pods]);

  const selectedPod = useMemo(
    () => pods?.find(({ pod_id }: Pod) => pod_id === selectedPodId),
    [pods, selectedPodId]
  );

  const handlePodSelect = useCallback((podId: string) => {
    setSelectedPodId(podId);
  }, []);

  useEffect(() => {
    if (podsOptions?.length && !selectedPodId) {
      setSelectedPodId(podsOptions[0].value);
    }
  }, [pods, podsOptions, selectedPodId]);

  return {
    pods,
    podsOptions,
    isPodsLoading,
    isPodsError,
    createPod,
    updatePod,
    isCreatePodLoading,
    isUpdatePodLoading,
    handlePodSelect,
    selectedPod,
    selectedPodId, // for testing only
  };
};
