import {
  CentralInfoService,
  LeadPursuitService,
  OpsPodsService,
  PartnerLeadsService,
} from "@sindeo/rest-services";

export class Rest {
  static get cis(): CentralInfoService {
    return CentralInfoService.getInstance();
  }

  static get ops(): OpsPodsService {
    return OpsPodsService.getInstance();
  }

  static get lps(): LeadPursuitService {
    return LeadPursuitService.getInstance();
  }

  static get pls(): PartnerLeadsService {
    return PartnerLeadsService.getInstance();
  }
}
