import { MaTeam } from "@sindeo/rest-services";
import { set } from "lodash";
import { logger } from "services/logger";

export const processTeam = (
  { nmlsIds }: MaTeam,
  { mortgage_advisors }: Record<string, Record<string, string>>
) => {
  return [
    {
      title: "Team Member",
      list_identifier: "mortgage_advisors",
      item_identifier: "nmls_id",
      items: nmlsIds.reduce((acc, nmlsId) => {
        const advisor = mortgage_advisors[nmlsId];
        if (!advisor) {
          logger.error("Could not find advisor with NMLS ID ", nmlsId);
        } else {
          set(acc, nmlsId, advisor);
        }
        return acc;
      }, {}),
    },
  ];
};
