import { useQuery, useQueryClient } from "@sindeo/react-data-query";
import { MortgageAdvisor } from "@sindeo/rest-services";
import { Rest } from "services/rest/rest";

export const useMortgageAdvisors = (doSync = false) => {
  const queryKey = ["mortgage-advisors", doSync];
  const queryClient = useQueryClient();

  const {
    data: mortgageAdvisors,
    isLoading: isMortgageAdvisorsLoading,
    isFetching: isMortgageAdvisorsFetching,
    error: isMortgageAdvisorsError,
  } = useQuery<MortgageAdvisor[], string>({
    queryKey,
    queryFn: () => Rest.ops.getMortgageAdvisors(doSync),
  });

  const refetchMortgageAdvisors = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return {
    mortgageAdvisors,
    refetchMortgageAdvisors,
    isMortgageAdvisorsLoading,
    isMortgageAdvisorsFetching,
    isMortgageAdvisorsError,
  };
};
