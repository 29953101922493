import * as Yup from "yup";

export const MINIMUM_SPILLOVER_TIME = 15;
export const MINIMUM_RANKING = 1;
export const MAXIMUM_RANKING = 10;

const SPILLOVER_ERROR_MESSAGE = `Spill over time should be at least ${MINIMUM_SPILLOVER_TIME}`;
const RANKING_ERROR_MESSAGE = `Ranking should be between ${MINIMUM_RANKING} and ${MAXIMUM_RANKING}`;

export const LEAD_GROUP_SCHEMA = Yup.object({
  rank: Yup.number()
    .required()
    .min(MINIMUM_RANKING, RANKING_ERROR_MESSAGE)
    .max(MAXIMUM_RANKING, RANKING_ERROR_MESSAGE)
    .typeError("Please enter a valid number")
    .defined(),
  spillOverTime: Yup.number()
    .required()
    .min(MINIMUM_SPILLOVER_TIME, SPILLOVER_ERROR_MESSAGE)
    .typeError("Please enter a valid number")
    .defined(),
});
