import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Stack,
  Typography,
} from "@achieve/sunbeam";
import {
  ConfirmationDialog,
  useConfirmationDialog,
} from "@sindeo/react-components";
import { OperationsUserForm } from "forms/OperationsUserForm/OperationsUserForm";
import { useAddEditModal } from "hooks/useAddEditModal/useAddEditModal";
import { useOperationsPersonnel } from "hooks/useOperationsPersonnel/useOperationsPersonnel";
import { useOperationsTypes } from "hooks/useOperationsTypes/useOperationsTypes";
import { usePods } from "hooks/usePods/usePods";
import { AddButton } from "ui/AddButton/AddButton";
import AddEditModal from "ui/AddEditModal";
import { DeleteButton } from "ui/DeleteButton/DeleteButton";
import Selector from "ui/Selector";

import {
  buildDefaultValues,
  buildUserRolesList,
  DELETE_CONFIRMATION_TITLE_MESSAGE,
  USER_SCHEMA,
} from "components/OperationsUsersManager/OperationsUsersManager.utils";

const OperationsUsersManager: React.FC = () => {
  const { operationsTypes, isOperationsTypesLoading } = useOperationsTypes();
  const { pods, isPodsLoading } = usePods();
  const {
    selectorOptions,
    selectedUser,
    handleUserSelect,
    isOperationsPersonnelLoading,
    createOperationsUser,
    isCreateUserLoading,
    updateOperationsUser,
    isUpdateUserLoading,
    deleteOperationsUser,
    isDeleteOperationsUserLoading,
  } = useOperationsPersonnel();
  const { isModalOpen, isEditMode, openModal, closeModal } = useAddEditModal();
  const [rolesList, setRolesList] = useState<{ role: string; pod: string }[]>(
    []
  );

  const {
    props: confirmDeleteDialogProps,
    actions: { open: openDeleteConfirmationDialog },
  } = useConfirmationDialog({
    closeWhenConfirmed: true,
    isCloseIconShown: false,
  });

  const handleAddEditUser = useCallback(
    async ({ name, user_name, roles }) => {
      const selectedRoles = Object.keys(roles)
        .filter((key) => roles[key])
        .map((key) => ({ operations_type_code: key }));

      const payload = {
        name,
        user_name,
        operations_types: selectedRoles,
      };

      if (isEditMode) {
        await updateOperationsUser(payload);
      } else {
        await createOperationsUser(payload);
      }
      closeModal();
    },
    [closeModal, createOperationsUser, isEditMode, updateOperationsUser]
  );

  const handleDeleteUser = useCallback(async () => {
    const { isConfirmed } = await openDeleteConfirmationDialog({
      title: DELETE_CONFIRMATION_TITLE_MESSAGE,
    });
    if (isConfirmed) {
      await deleteOperationsUser();
    }
  }, [deleteOperationsUser, openDeleteConfirmationDialog]);

  const canDeleteUser = useMemo(() => {
    return rolesList.every((role) => role.pod === "N/A");
  }, [rolesList]);

  useEffect(() => {
    if (selectedUser && pods?.length && operationsTypes?.length) {
      setRolesList(
        buildUserRolesList(
          selectedUser?.operations_types,
          pods,
          operationsTypes
        )
      );
    }
  }, [operationsTypes, pods, selectedUser]);

  if (
    isOperationsPersonnelLoading ||
    isOperationsTypesLoading ||
    isPodsLoading ||
    !operationsTypes?.length ||
    !selectorOptions?.length ||
    !selectedUser ||
    isCreateUserLoading ||
    isUpdateUserLoading ||
    isDeleteOperationsUserLoading
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack spacing={2} width={"100%"} justifyContent={"space-between"}>
      <Stack spacing={2}>
        <AddButton onClick={openModal} isHidden={isEditMode} />

        <Selector
          name={"operationsUser"}
          options={selectorOptions}
          onChange={handleUserSelect}
          initialValue={selectedUser?.operations_personnel_id}
          data-testid="operations-personnel-select"
        />

        <Stack direction="row" justifyContent="space-between">
          <Typography variant={"displayXS30"} fontWeight={"medium"}>
            Current role(s)
          </Typography>
          <Typography variant={"displayXS30"} fontWeight={"medium"}>
            In pod(s)
          </Typography>
        </Stack>
        <List
          sx={{
            listStyleType: "disc",
            paddingLeft: "24px",
            marginTop: "0",
          }}
        >
          {rolesList.map(({ role, pod }) => (
            <ListItem
              key={`${role}-${pod}`}
              sx={{ display: "list-item", padding: "0" }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={"bodyS20"}>{role}</Typography>
                <Typography variant={"bodyS20"}>{pod}</Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Stack>
      <Button onClick={openModal}>Edit</Button>
      <DeleteButton onClick={handleDeleteUser} disabled={!canDeleteUser}>
        Delete
      </DeleteButton>
      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          title={`${isEditMode ? "Edit" : "New"} Operations User`}
          onClose={closeModal}
          onSave={handleAddEditUser}
          defaultValues={buildDefaultValues(
            operationsTypes,
            isEditMode ? selectedUser : undefined
          )}
          schema={USER_SCHEMA(isEditMode ? [] : selectorOptions)}
        >
          <OperationsUserForm operationsTypes={operationsTypes} />
        </AddEditModal>
      )}
      <ConfirmationDialog {...confirmDeleteDialogProps} />
    </Stack>
  );
};
export default OperationsUsersManager;
