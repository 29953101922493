import { ControlledSelectOption } from "@sindeo/react-forms";
import type {
  OperationsPersonnel,
  OperationsType,
  Pod,
} from "@sindeo/rest-services";
import { set, some } from "lodash";
import { NAME_SCHEMA } from "ui/AddEditModal/AddEditModal";
import * as Yup from "yup";

export const buildUserRolesList = (
  roles: OperationsPersonnel["operations_types"],
  pods: Pod[],
  operationsTypes: OperationsType[]
) =>
  roles?.map(({ operations_type_code, operations_personnel_type_id }) => ({
    role:
      operationsTypes?.find(
        ({ operations_type_code: code }) => code === operations_type_code
      )?.type_name ?? "",
    pod:
      pods?.find(({ operations_personnel }) =>
        operations_personnel.find(
          ({ operations_personnel_type_id: id }) =>
            id === operations_personnel_type_id
        )
      )?.name ?? "N/A",
  }));

export const getRoles = (
  operationsTypes: OperationsType[],
  userRoles: OperationsPersonnel["operations_types"]
) =>
  operationsTypes.reduce((acc, { operations_type_code }) => {
    set(
      acc,
      operations_type_code,
      userRoles.some(
        ({ operations_type_code: code }) => code === operations_type_code
      )
    );
    return acc;
  }, {});

export const buildDefaultValues = (
  operationsTypes: OperationsType[],
  user: OperationsPersonnel | undefined
) => {
  return {
    name: user?.name ?? "",
    user_name: user?.user_name ?? "",
    roles: getRoles(operationsTypes, user?.operations_types ?? []),
  };
};

export const USER_SCHEMA = (nameOptions: ControlledSelectOption[]) =>
  Yup.object(NAME_SCHEMA(nameOptions.map(({ label }) => label))).shape({
    user_name: Yup.string().required("User ID is required"),
    roles: Yup.object().test(
      "roles",
      "Must select at least one role",
      (values) => some(values, (value) => value)
    ),
  });

export const DELETE_CONFIRMATION_TITLE_MESSAGE =
  "Are you sure you want to delete this user?";
