import React, { useCallback, useMemo, useState } from "react";
import {
  Container,
  ListDataContextProviderProps,
  ListDataContextValue,
} from "contexts/ListDataContext/ListDataContext.interface";
import { noop, pickBy } from "lodash";
import { SingleList } from "ui/ListBox/ListBox.interface";

const ListDataContextValueDefault: ListDataContextValue = {
  listInEdit: {} as SingleList,
  currentChoiceList: {},
  onListEdit: noop,
  container: {} as Container,
  allowSaveEmptyList: false,
};

export const ListDataContext = React.createContext(ListDataContextValueDefault);
ListDataContext.displayName = "ListDataContext";

const ListDataContextProvider: React.FC<ListDataContextProviderProps> = ({
  collections,
  container,
  allowSaveEmptyList = false,
  children,
}) => {
  const [listInEdit, setListInEdit] = useState<SingleList>({} as SingleList);
  const [currentChoiceList, setCurrentChoiceList] = useState<
    Record<string, string>
  >({});

  const onListEdit = useCallback(
    (list) => {
      setListInEdit(list);
      // exclude values that are in list to edit, from the choice list
      setCurrentChoiceList(
        pickBy(
          collections[list.list_identifier],
          (_value, id) => !Object.keys(list.items).includes(id)
        )
      );
    },
    [collections]
  );

  const value = useMemo(
    () => ({
      listInEdit,
      currentChoiceList,
      onListEdit,
      container,
      allowSaveEmptyList,
    }),
    [allowSaveEmptyList, listInEdit, currentChoiceList, onListEdit, container]
  );

  return (
    <ListDataContext.Provider value={value}>
      {children}
    </ListDataContext.Provider>
  );
};

export default ListDataContextProvider;
