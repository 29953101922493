import React, { FunctionComponent } from "react";
import { Alert, AlertTitle } from "@achieve/sunbeam";

import type { ErrorAlertProps } from "./ErrorAlert.interface";

const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({
  error,
  ...restProps
}) => {
  return (
    <Alert severity={"error"} {...restProps}>
      {error.name && <AlertTitle>{error.name}</AlertTitle>}
      {error.message}
    </Alert>
  );
};

export default ErrorAlert;
