import React from "react";
import { Typography } from "@achieve/sunbeam";
import { ControlledCheckbox, ControlledInput } from "@sindeo/react-forms";
import { OperationsUserFormProps } from "forms/OperationsUserForm/OperationsUserForm.interface";

export const OperationsUserForm: React.FC<OperationsUserFormProps> = ({
  operationsTypes,
}) => {
  return (
    <>
      <ControlledInput label="User Name" name="name" />
      <ControlledInput label="User ID" name="user_name" />
      <Typography variant={"displayXS30"}>Role(s)</Typography>
      {operationsTypes.map(({ operations_type_code, type_name }) => (
        <ControlledCheckbox
          css={{ marginLeft: "8px", marginBottom: "0" }}
          key={operations_type_code}
          label={type_name}
          labelTypography={{ variant: "bodyS20" }}
          name={`roles.${operations_type_code}`}
        />
      ))}
    </>
  );
};
