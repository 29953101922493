import routes from "constants/routes";
import { MenuButtonProps } from "ui/MenuButton/MenuButton.interface";

export const WELCOME_MENU: MenuButtonProps[] = [
  {
    title: "Lead Groups & Sales Teams",
    options: [
      {
        title: "Lead Groups",
        path: routes.leadGroups,
      },
      {
        title: "Sales Teams",
        path: routes.salesTeams,
      },
    ],
  },
  {
    title: "MAs & Operations Pods",
    options: [
      {
        title: "Pods",
        path: routes.pods,
      },
      {
        title: "Mortgage Advisors",
        path: routes.mortgageAdvisors,
      },
      {
        title: "Operations Users",
        path: routes.operationsUsers,
      },
      {
        title: "Roles",
        path: routes.roles,
      },
    ],
  },
  { title: "MA Daily Caps", path: "/ma-caps" },
  { title: "Lead Source Restrictions", path: "/lead-source-restrictions" },
];
