import { css } from "@emotion/react";

export const numberInput = css`
  margin: 0 !important;

  div > div > input {
    padding: 8px !important;
  }

  label {
    display: none;
  }
`;
