import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  LoadingButton,
  Stack,
  Typography,
} from "@achieve/sunbeam";
import { useMortgageAdvisors } from "hooks/useMortgageAdvisors/useMortgageAdvisors";
import { usePods } from "hooks/usePods/usePods";
import { sortByField } from "utilities/sort";

import { getAssignment } from "components/MortgageAdvisorsManager/MortgageAdvisorsManager.utils";

const MortgageAdvisorsManager: React.FC = () => {
  const [doSynchronize, setDoSynchronize] = useState<boolean>(false);

  const {
    mortgageAdvisors,
    isMortgageAdvisorsLoading,
    refetchMortgageAdvisors,
    isMortgageAdvisorsFetching,
  } = useMortgageAdvisors(doSynchronize);

  const { pods, isPodsLoading } = usePods();

  const mortgageAdvisorsWithAssignments = useMemo(() => {
    if (!mortgageAdvisors?.length || !pods?.length) {
      return [];
    }

    return sortByField(
      mortgageAdvisors.map((m) => getAssignment(m, pods)),
      "name"
    );
  }, [mortgageAdvisors, pods]);

  const handleSalesforceSync = useCallback(() => {
    setDoSynchronize(true);
    refetchMortgageAdvisors();
  }, [refetchMortgageAdvisors]);

  if (isMortgageAdvisorsLoading || isPodsLoading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack spacing={2} width={"100%"}>
      <Stack direction="row" justifyContent="space-between" paddingX={1}>
        <Typography variant={"displayXS30"} fontWeight={"medium"}>
          Mortgage Advisor
        </Typography>
        <Typography variant={"displayXS30"} fontWeight={"medium"}>
          Pod
        </Typography>
      </Stack>
      <Box
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "4px",
          height: "350px",
          overflow: "auto",
          padding: "16px",
        }}
      >
        <List>
          {mortgageAdvisorsWithAssignments.map(({ name, pod }) => (
            <ListItem key={name} sx={{ display: "list-item", padding: "0" }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={"bodyS20"}>{name}</Typography>
                <Typography variant={"bodyS20"}>{pod}</Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Box>
      <LoadingButton
        onClick={handleSalesforceSync}
        loading={isMortgageAdvisorsFetching}
      >
        Salesforce Sync
      </LoadingButton>
    </Stack>
  );
};

export default MortgageAdvisorsManager;
