import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import { ControlledSelectOption } from "@sindeo/react-forms";
import {
  OperationsPersonnel,
  UpdateOperationsPersonnelPayload,
} from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";
import { sortByField } from "utilities/sort";

export const useOperationsPersonnel = () => {
  const queryKey = ["operations-personnel"];
  const queryClient = useQueryClient();

  const [selectedUserId, setSelectedUserId] = useState<string>("");

  const {
    data: operationsPersonnel,
    isLoading: isOperationsPersonnelLoading,
    error: isOperationsPersonnelError,
  } = useQuery<OperationsPersonnel[], string>({
    queryKey,
    queryFn: () => Rest.ops.getOperationsPersonnel(),
  });

  const { mutateAsync: createOperationsUser, isLoading: isCreateUserLoading } =
    useMutation(
      (user: UpdateOperationsPersonnelPayload) =>
        Rest.ops.createOperationsPersonnel({ data: [user] }),
      {
        onSuccess: async (data) => {
          if (data?.length) {
            setSelectedUserId(data[0].operations_personnel_id);
          }
          await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
          logger.error("Error while creating a pod", error);
        },
      }
    );

  const { mutateAsync: updateOperationsUser, isLoading: isUpdateUserLoading } =
    useMutation(
      (user: UpdateOperationsPersonnelPayload) =>
        Rest.ops.updateOperationsPersonnel(selectedUserId, user),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error) => {
          logger.error(
            "Error while updating a pod with id ",
            selectedUserId,
            error
          );
        },
      }
    );

  const {
    mutateAsync: deleteOperationsUser,
    isLoading: isDeleteOperationsUserLoading,
  } = useMutation(() => Rest.ops.deleteOperationsPersonnel(selectedUserId), {
    onSuccess: async () => {
      setSelectedUserId("");
      await queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      logger.error(
        "Error while deleting Operations Personnel with id ",
        selectedUserId,
        error
      );
    },
  });

  const selectorOptions = useMemo(() => {
    const options =
      operationsPersonnel?.map(
        ({ operations_personnel_id, name }: OperationsPersonnel) => ({
          label: name,
          value: operations_personnel_id,
        })
      ) ?? [];
    return sortByField<ControlledSelectOption>(options);
  }, [operationsPersonnel]);

  const selectedUser = useMemo(
    () =>
      operationsPersonnel?.find(
        ({ operations_personnel_id }: OperationsPersonnel) =>
          operations_personnel_id === selectedUserId
      ),
    [operationsPersonnel, selectedUserId]
  );

  const handleUserSelect = useCallback((userId: string) => {
    setSelectedUserId(userId);
  }, []);

  useEffect(() => {
    if (selectorOptions?.length && !selectedUserId) {
      setSelectedUserId(selectorOptions[0].value);
    }
  }, [selectedUserId, selectorOptions]);

  return {
    operationsPersonnel,
    isOperationsPersonnelLoading,
    isOperationsPersonnelError,
    selectorOptions,
    selectedUser,
    handleUserSelect,
    createOperationsUser,
    isCreateUserLoading,
    updateOperationsUser,
    isUpdateUserLoading,
    deleteOperationsUser,
    isDeleteOperationsUserLoading,
    selectedUserId, // for testing only
  };
};
