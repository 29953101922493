import { get } from "lodash";

export const sortListByName = (items: Record<string, string>) =>
  Object.entries(items).sort((a, b) => a[1].localeCompare(b[1]));

export const sortIdsByName = (
  ids: readonly string[],
  dictionary: Record<string, string>
) => [...ids].sort((a, b) => dictionary[a].localeCompare(dictionary[b]));

export const sortByField = <T>(items: T[], field = "label") =>
  [...items].sort((a, b) =>
    (get(a, field, "") as string).localeCompare(get(b, field, "") as string)
  );
