import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import { MortgageAdvisor, UpdateMaCapsPayload } from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";

export const useMortgageAdvisorCaps = (doSync = false) => {
  const queryKey = ["mortgage-advisor-caps", doSync];
  const queryClient = useQueryClient();

  const {
    data: mortgageAdvisorCaps,
    isLoading: isMortgageAdvisorCapsLoading,
    isFetching: isMortgageAdvisorCapsFetching,
    error: isMortgageAdvisorCapsError,
  } = useQuery<MortgageAdvisor[], string>({
    queryKey,
    queryFn: () => Rest.lps.getMortgageAdvisors(doSync),
  });

  const {
    mutateAsync: updateMortgageAdvisorCaps,
    isLoading: isUpdateMortgageAdvisorCapsLoading,
    isError: isUpdateMortgageAdvisorCapsError,
  } = useMutation(
    async (maCaps: UpdateMaCapsPayload[]) => {
      return Rest.lps.updateMortgageAdvisorCap(maCaps);
    },
    {
      onSuccess: async () => {
        await refetchMortgageAdvisorCaps();
      },
      onError: (error) => {
        logger.error("Error while updating Mortgage Advisor Caps", error);
      },
    }
  );
  const refetchMortgageAdvisorCaps = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  return {
    mortgageAdvisorCaps,
    refetchMortgageAdvisorCaps,
    isMortgageAdvisorCapsLoading,
    isMortgageAdvisorCapsFetching,
    isMortgageAdvisorCapsError,
    updateMortgageAdvisorCaps,
    isUpdateMortgageAdvisorCapsLoading,
    isUpdateMortgageAdvisorCapsError,
  };
};
