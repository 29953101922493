import { MortgageAdvisor, Pod } from "@sindeo/rest-services";

export const getAssignment = (
  { mortgage_advisor_id, first_name, last_name }: MortgageAdvisor,
  pods: Pod[]
) => ({
  name: `${first_name} ${last_name}`,
  pod:
    pods.find(({ mortgage_advisors }) =>
      mortgage_advisors.find(
        ({ mortgage_advisor_id: id }) => id === mortgage_advisor_id
      )
    )?.name ?? "N/A",
});
