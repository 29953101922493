import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@sindeo/react-data-query";
import { ControlledSelectOption } from "@sindeo/react-forms";
import {
  CreateLeadSourceGroupPayload,
  LeadSourceGroup,
} from "@sindeo/rest-services";
import { useAdvisorMappings } from "hooks/useAdvisorMappings/useAdvisorMappings";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";
import { sortByField } from "utilities/sort";

export const useLeadGroups = () => {
  const {
    groups: leadGroups,
    teams: maTeams,
    mappings,
    refreshAdvisorMappings,
    isUpdateAdvisorMappingsLoading,
    isAdvisorMappingsLoading,
    isAdvisorMappingsError,
  } = useAdvisorMappings();

  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);

  const {
    mutateAsync: createLeadSourceGroup,
    isLoading: isCreateLeadSourceGroupLoading,
  } = useMutation(
    (group: Partial<LeadSourceGroup>) =>
      Rest.lps.createLeadSourceGroup({
        data: [group],
      } as CreateLeadSourceGroupPayload),
    {
      onSuccess: async (data) => {
        if (data?.length) {
          setSelectedGroupId(data[0].groupId);
        }
        await refreshAdvisorMappings();
      },
      onError: (error) => {
        logger.error("Error while creating a Lead Source Group", error);
      },
    }
  );

  const {
    mutateAsync: updateLeadSourceGroup,
    isLoading: isUpdateLeadSourceGroupLoading,
  } = useMutation(
    (group: Partial<LeadSourceGroup>) =>
      Rest.lps.updateLeadSourceGroup(selectedGroupId, group),
    {
      onSuccess: async () => {
        await refreshAdvisorMappings();
      },
      onError: (error) => {
        logger.error(
          "Error while updating a Lead Source Group with id ",
          selectedGroupId,
          error
        );
      },
    }
  );

  const leadGroupsOptions = useMemo(() => {
    const options =
      leadGroups?.map(({ groupName, groupId }: LeadSourceGroup) => ({
        label: groupName,
        value: groupId.toString(),
      })) ?? [];
    return sortByField<ControlledSelectOption>(options);
  }, [leadGroups]);

  const selectedGroup = useMemo(
    () =>
      leadGroups?.find(
        ({ groupId }: LeadSourceGroup) => groupId === selectedGroupId
      ),
    [leadGroups, selectedGroupId]
  );

  const handleGroupSelect = useCallback((groupId: string) => {
    setSelectedGroupId(parseInt(groupId));
  }, []);

  useEffect(() => {
    if (leadGroupsOptions?.length && !selectedGroupId) {
      setSelectedGroupId(parseInt(leadGroupsOptions[0].value));
    }
  }, [leadGroupsOptions, selectedGroupId]);

  return {
    leadGroupsOptions,
    maTeams,
    mappings,
    selectedGroup,
    handleGroupSelect,
    isAdvisorMappingsLoading,
    isAdvisorMappingsError,
    createLeadSourceGroup,
    isCreateLeadSourceGroupLoading,
    updateLeadSourceGroup,
    isUpdateLeadSourceGroupLoading,
    isUpdateAdvisorMappingsLoading,
  };
};
