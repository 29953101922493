import React from "react";
import { Button } from "@achieve/sunbeam";

import * as styles from "./DeleteButton.styles";

export const DeleteButton: React.FC<Pick<Button, "onClick" | "disabled">> = ({
  onClick,
  disabled = false,
}) => (
  <Button
    variant="outlined"
    css={styles.deleteButton}
    onClick={onClick}
    disabled={disabled}
  >
    Delete
  </Button>
);
