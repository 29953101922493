import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@sindeo/react-data-query";
import { ControlledSelectOption } from "@sindeo/react-forms";
import { CreateMaTeamPayload, MaTeam } from "@sindeo/rest-services";
import { useAdvisorMappings } from "hooks/useAdvisorMappings/useAdvisorMappings";
import { useMortgageAdvisors } from "hooks/useMortgageAdvisors/useMortgageAdvisors";
import { set } from "lodash";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";
import { sortByField } from "utilities/sort";

export const useSalesTeams = () => {
  const { teams, isAdvisorMappingsLoading, refreshAdvisorMappings } =
    useAdvisorMappings("teams");
  const { mortgageAdvisors, isMortgageAdvisorsLoading } = useMortgageAdvisors();

  const [selectedTeamId, setSelectedTeamId] = useState<number>(0);

  const { mutateAsync: createSalesTeam, isLoading: isCreateSalesTeamLoading } =
    useMutation(
      (team: Partial<MaTeam>) =>
        Rest.lps.createMaTeam({ data: [team] } as CreateMaTeamPayload),
      {
        onSuccess: async (data) => {
          if (data?.length) {
            setSelectedTeamId(data[0].teamId);
          }
          await refreshAdvisorMappings();
        },
        onError: (error) => {
          logger.error("Error while creating a pod", error);
        },
      }
    );

  const { mutateAsync: updateSalesTeam, isLoading: isUpdateSalesTeamLoading } =
    useMutation(
      (team: Partial<MaTeam>) =>
        Rest.lps.updateMaTeam(selectedTeamId, team as MaTeam),
      {
        onSuccess: async () => {
          await refreshAdvisorMappings();
        },
        onError: (error) => {
          logger.error(
            "Error while updating a pod with id ",
            selectedTeamId,
            error
          );
        },
      }
    );

  const selectorOptions = useMemo(() => {
    const options =
      teams?.map(({ maTeamName, teamId }: MaTeam) => ({
        label: maTeamName,
        value: teamId.toString(),
      })) ?? [];
    return sortByField<ControlledSelectOption>(options);
  }, [teams]);

  const collections = useMemo(
    () => ({
      mortgage_advisors: (mortgageAdvisors ?? []).reduce(
        (acc, { nmls_id, first_name, last_name }) => {
          set(acc, nmls_id, `${first_name} ${last_name}`);
          return acc;
        },
        {}
      ),
    }),
    [mortgageAdvisors]
  );

  const selectedTeam = useMemo(
    () => teams?.find(({ teamId }: MaTeam) => teamId === selectedTeamId),
    [teams, selectedTeamId]
  );

  const handleTeamSelect = useCallback((teamId: string) => {
    setSelectedTeamId(parseInt(teamId));
  }, []);

  useEffect(() => {
    if (selectorOptions?.length && !selectedTeamId) {
      setSelectedTeamId(parseInt(selectorOptions[0].value));
    }
  }, [teams, selectorOptions, selectedTeamId]);

  return {
    selectedTeam,
    selectorOptions,
    collections,
    handleTeamSelect,
    createSalesTeam,
    updateSalesTeam,
    isSalesTeamsLoading: useMemo(
      () =>
        isAdvisorMappingsLoading ||
        isMortgageAdvisorsLoading ||
        isCreateSalesTeamLoading ||
        isUpdateSalesTeamLoading,
      [
        isAdvisorMappingsLoading,
        isMortgageAdvisorsLoading,
        isCreateSalesTeamLoading,
        isUpdateSalesTeamLoading,
      ]
    ),
  };
};
