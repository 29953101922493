import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import {
  AdvisorMappings,
  GroupTeamMapping,
  MappingType,
} from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";

export const useAdvisorMappings = (mappingType?: MappingType) => {
  const queryKey = ["advisor-mappings", mappingType];
  const queryClient = useQueryClient();

  const {
    data: advisorMappings,
    isLoading: isAdvisorMappingsLoading,
    error: isAdvisorMappingsError,
  } = useQuery<AdvisorMappings, string>({
    queryKey,
    queryFn: () => Rest.lps.getAdvisorMappings(mappingType),
  });

  const {
    mutateAsync: updateAdvisorMappings,
    isLoading: isUpdateAdvisorMappingsLoading,
  } = useMutation(
    (mapping: GroupTeamMapping) => Rest.lps.updateAdvisorMappings(mapping),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
      onError: (error) => {
        logger.error("Error while updating advisor mapping", error);
      },
    }
  );

  const refreshAdvisorMappings = async () => {
    await queryClient.invalidateQueries({ queryKey });
  };

  return {
    ...advisorMappings,
    refreshAdvisorMappings,
    updateAdvisorMappings,
    isUpdateAdvisorMappingsLoading,
    isAdvisorMappingsLoading,
    isAdvisorMappingsError,
  };
};
