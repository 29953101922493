import React from "react";
import { ControlledInput } from "@sindeo/react-forms";

export const OperationsTypeForm: React.FC = () => {
  return (
    <>
      <ControlledInput label="Role Name" name="name" />
      <ControlledInput label="Role Abbreviation" name="operations_type_code" />
    </>
  );
};
