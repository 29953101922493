import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import {
  LeadSourceRestriction,
  LeadSourceRestrictionPayload,
} from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";

export const useLeadSourceRestrictions = () => {
  const queryKey = ["lead-source-restrictions"];
  const queryClient = useQueryClient();

  const {
    data: leadSourceRestrictions,
    isLoading: isLeadSourceRestrictionsLoading,
    error: leadSourceRestrictionsError,
  } = useQuery<LeadSourceRestriction[], string>({
    queryKey,
    queryFn: () => Rest.lps.getLeadSourceRestrictions(),
  });

  const {
    mutateAsync: updateLeadSourceRestrictions,
    isLoading: isUpdateLeadSourceRestrictionsLoading,
  } = useMutation(
    (data: LeadSourceRestrictionPayload[]) =>
      Rest.lps.updateLeadSourceRestrictions(data),
    {
      onSuccess: async () => {
        await refreshLeadSourceRestrictions();
      },
      onError: (error) => {
        logger.error("Error while updating Lead Source Restrictions", error);
      },
    }
  );

  const refreshLeadSourceRestrictions = async () =>
    await queryClient.invalidateQueries(queryKey);

  return {
    leadSourceRestrictions,
    isLeadSourceRestrictionsLoading,
    leadSourceRestrictionsError,
    updateLeadSourceRestrictions,
    isUpdateLeadSourceRestrictionsLoading,
  };
};
