import React from "react";
import { Plus } from "react-feather";
import { Box, Button, Typography } from "@achieve/sunbeam";

export const AddButton: React.FC<
  Pick<Button, "onClick"> & { isHidden?: boolean }
> = ({ onClick, isHidden = false }) =>
  isHidden ? (
    <Box height={32} />
  ) : (
    <Button variant={"text"} onClick={onClick} sx={{ alignSelf: "end" }}>
      <Plus />
      <Typography
        variant="displayS10"
        fontWeight={"bold"}
        sx={{ verticalAlign: "top", paddingLeft: 0.5 }}
      >
        Add
      </Typography>
    </Button>
  );
