import React from "react";
import { Box, Typography } from "@achieve/sunbeam";
import { WELCOME_MENU } from "constants/welcome-menu";
import { kebabCase } from "lodash";
import MenuButton from "ui/MenuButton/MenuButton";

const Welcome: React.VFC = () => {
  return (
    <Box
      sx={({ palette }) => ({
        alignItems: "center",
        backgroundColor: palette.primary.main,
        color: "white",
        display: "flex",
        flexDirection: "column",
        height: 600,
        justifyContent: "center",
        position: "absolute",
        width: "100vw",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant={"displayM20"}>Welcome to the</Typography>
        <Typography variant={"displayM30"} fontWeight={"bold"}>
          Sales Manager Console!
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          paddingX: 2,
          paddingY: 4,
          width: 560,
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        {WELCOME_MENU.map((props) => (
          <MenuButton key={kebabCase(props.title)} {...props} />
        ))}
      </Box>
    </Box>
  );
};
export default Welcome;
