import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Stack } from "@achieve/sunbeam";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { LeadSourceRestriction, MortgageAdvisor } from "@sindeo/rest-services";
import ListDataContextProvider from "contexts/ListDataContext/ListDataContext";
import { useLeadSourceRestrictions } from "hooks/useLeadSourceRestrictions/useLeadSourceRestrictions";
import { useLeadSources } from "hooks/useLeadSources/useLeadSources";
import { useMortgageAdvisors } from "hooks/useMortgageAdvisors/useMortgageAdvisors";
import ListBox from "ui/ListBox";
import Selector from "ui/Selector/Selector";

import { convertStringArrayToObject } from "./LeadSourceRestrictions.utils";

const LeadSourceRestrictionsManager: React.FC = () => {
  const [selectedMA, setSelectedMA] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<UserClaims>();
  const [editMode, setEditMode] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const { mortgageAdvisors, isMortgageAdvisorsLoading } =
    useMortgageAdvisors(true);

  const { leadSources } = useLeadSources();

  const {
    leadSourceRestrictions,
    isLeadSourceRestrictionsLoading,
    updateLeadSourceRestrictions,
    isUpdateLeadSourceRestrictionsLoading,
  } = useLeadSourceRestrictions();

  const advisorOptions = useMemo(() => {
    return mortgageAdvisors?.map((advisor: MortgageAdvisor) => {
      return {
        label: `${advisor.first_name}  ${advisor.last_name}`,
        value: advisor.nmls_id,
      };
    });
  }, [mortgageAdvisors]);

  const handleAdvisorUpdate = useCallback(async (value: string) => {
    setSelectedMA(value);
  }, []);

  const handleRestrictionUpdate = useCallback(
    (_list, values: string[]) => {
      if (selectedMA && leadSourceRestrictions && userInfo?.email) {
        const leadSourceRestrictionPayload = [
          {
            nmls_id: selectedMA,
            restricted_lead_sources: Object.values(values),
            updated_by: userInfo.email,
          },
        ];
        updateLeadSourceRestrictions(leadSourceRestrictionPayload);
      }
    },
    [
      leadSourceRestrictions,
      selectedMA,
      updateLeadSourceRestrictions,
      userInfo?.email,
    ]
  );
  const toggleEditMode = useCallback(() => {
    setEditMode((old) => !old);
  }, []);
  const selectedMARestrictions = useMemo(() => {
    const restrictions = leadSourceRestrictions?.filter(
      (ma: LeadSourceRestriction) => ma.nmls_id === selectedMA
    );
    return restrictions?.length ? restrictions[0].restricted_lead_sources : [];
  }, [leadSourceRestrictions, selectedMA]);

  const collections = useMemo(() => {
    if (leadSources) {
      return {
        maRestrictions: leadSources
          ? convertStringArrayToObject(leadSources)
          : {},
      };
    }
    return {};
  }, [leadSources]);

  const initialValue = useMemo(() => {
    if (selectedMA) {
      return selectedMA;
    }
    return advisorOptions ? advisorOptions[0]?.value : undefined;
  }, [advisorOptions, selectedMA]);

  useEffect(() => {
    if (advisorOptions) {
      setSelectedMA(advisorOptions[0].value);
    }
  }, [advisorOptions]);

  useEffect(() => {
    if (!userInfo) {
      oktaAuth?.token?.getUserInfo().then((info) => {
        return setUserInfo(info);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]); // Update if authState changes

  const showSpinner = useMemo(
    () =>
      isLeadSourceRestrictionsLoading ||
      isMortgageAdvisorsLoading ||
      isUpdateLeadSourceRestrictionsLoading,
    [
      isLeadSourceRestrictionsLoading,
      isMortgageAdvisorsLoading,
      isUpdateLeadSourceRestrictionsLoading,
    ]
  );

  if (showSpinner) {
    return <CircularProgress />;
  }
  return (
    <Stack spacing={2} width={"100%"}>
      <Selector
        name={"LeadSourceRestrictions"}
        onChange={handleAdvisorUpdate}
        options={advisorOptions || []}
        initialValue={initialValue}
        disabled={editMode}
      />
      <ListDataContextProvider
        collections={collections as Record<string, Record<string, string>>}
        container={{ type: "leadSourceRestrictions", name: "MA" }}
        allowSaveEmptyList={true}
      >
        <ListBox
          lists={[
            {
              title: "Lead Source Restriction",
              items: convertStringArrayToObject(selectedMARestrictions) ?? {},
              list_identifier: "maRestrictions",
              hideContainerName: true,
            },
          ]}
          onUpdate={handleRestrictionUpdate}
          isEditMode={editMode}
        />
      </ListDataContextProvider>
      {!editMode && <Button onClick={toggleEditMode}>Edit</Button>}
      {editMode && <Button onClick={toggleEditMode}>Cancel</Button>}
    </Stack>
  );
};
export default LeadSourceRestrictionsManager;
