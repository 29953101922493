import React from "react";
import { AppBar, Container } from "@achieve/sunbeam";
import { AchieveLogoIcon } from "@sindeo/react-components";

const Header: React.VFC = () => {
  return (
    <AppBar
      position={"static"}
      color={"transparent"}
      component={"header"}
      sx={{ paddingY: 2 }}
    >
      <Container maxWidth={false}>
        <AchieveLogoIcon />
      </Container>
    </AppBar>
  );
};

export default Header;
