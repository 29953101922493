import React, { useCallback, useMemo } from "react";
import { useConfirmationDialog } from "@sindeo/react-components";
import {
  UseListEditorProps,
  UseListEditorResult,
} from "hooks/useListEditor/useListEditor.interface";
import { difference, intersection } from "lodash";

export const useListEditor = ({
  choiceList,
  chosenList,
  allowSaveEmptyList = false,
}: UseListEditorProps): UseListEditorResult => {
  const [checked, setChecked] = React.useState<readonly string[]>([]);
  const [selection, setSelection] = React.useState<readonly string[]>(
    Object.keys(chosenList?.items ?? {})
  );
  const [origin, setOrigin] = React.useState<readonly string[]>(
    Object.keys(choiceList)
  );

  const dictionary = { ...choiceList, ...chosenList?.items };

  const {
    props: confirmationDialogProps,
    actions: { open: openConfirmationDialog },
  } = useConfirmationDialog();

  const selectionChecked = useMemo(
    () => intersection(checked, selection),
    [checked, selection]
  );
  const originChecked = useMemo(
    () => intersection(checked, origin),
    [checked, origin]
  );

  const getBgColor = useCallback(
    (value: string) => (checked.includes(value) ? "aliceblue" : "transparent"),
    [checked]
  );

  const handleToggle = useCallback(
    (items: readonly string[], value: string) => {
      const newChecked = [...checked];
      // if the current checked value is from the opposite list, empty the checked list first
      if (checked.length && !items.includes(checked[0])) {
        newChecked.splice(0, checked.length);
      }

      const currentIndex = checked.indexOf(value);

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked]
  );

  const handleCheckedOrigin = useCallback(() => {
    setSelection(selection.concat(originChecked));
    setOrigin(difference(origin, originChecked));
    setChecked(difference(checked, originChecked));
  }, [checked, origin, originChecked, selection]);

  const handleCheckedSelection = useCallback(() => {
    setOrigin(origin.concat(selectionChecked));
    setSelection(difference(selection, selectionChecked));
    setChecked(difference(checked, selectionChecked));
  }, [checked, origin, selection, selectionChecked]);

  const isSaveDisabled = useMemo(() => {
    const chosenListKeys = Object.keys(chosenList?.items ?? {});

    // if no items are selected, disable Save
    if (!selection.length) {
      // however, if allowSaveEmptyList is true, disable Save only if the chosen list was empty originally
      if (allowSaveEmptyList) {
        return chosenListKeys.length === 0;
      } else {
        return true;
      }
    }

    return (
      chosenListKeys.length === selection.length &&
      chosenListKeys.every((key) => selection.includes(key))
    );
  }, [allowSaveEmptyList, chosenList?.items, selection]);

  const validateSave = useCallback(async () => {
    if (!selection.length) {
      const { isConfirmed } = await openConfirmationDialog({
        title: `${chosenList.title}s list is empty. Would you still like to save?`,
      });

      return isConfirmed;
    }

    return true;
  }, [chosenList.title, openConfirmationDialog, selection.length]);

  return {
    dictionary,
    selection,
    origin,
    handleToggle,
    getBgColor,
    selectionChecked,
    originChecked,
    handleCheckedSelection,
    handleCheckedOrigin,
    isSaveDisabled,
    confirmationDialogProps,
    validateSave,
    // items below are for testing only
    checked,
  };
};
