import { ComponentType } from "react";

import PageLayout from "components/PageLayout";
import { PageLayoutProps } from "components/PageLayout/PageLayout.interface";

export const withPageLayout = <P extends {}>(Component: ComponentType<P>) => {
  return (props: PageLayoutProps & P) => {
    return (
      <PageLayout {...props}>
        <Component {...props} />
      </PageLayout>
    );
  };
};
