import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Stack } from "@achieve/sunbeam";
import { MaTeam } from "@sindeo/rest-services";
import ListDataContextProvider from "contexts/ListDataContext/ListDataContext";
import { LeadGroupForm } from "forms/LeadGroupForm/LeadGroupForm";
import { LeadGroupFormPropsSubmit } from "forms/LeadGroupForm/LeadGroupForm.interface";
import { useAddEditModal } from "hooks/useAddEditModal/useAddEditModal";
import { useLeadGroups } from "hooks/useLeadGroups/useLeadGroups";
import { useLeadSources } from "hooks/useLeadSources/useLeadSources";
import { set } from "lodash";
import { AddButton } from "ui/AddButton/AddButton";
import AddEditModal from "ui/AddEditModal";
import ListBox from "ui/ListBox";
import Selector from "ui/Selector";

import { processLeadGroup } from "components/LeadGroupsManager/LeadGroupsManager.utils";

const LeadGroupsManager: React.FC = () => {
  const { leadSources, isLeadSourcesLoading } = useLeadSources();

  const {
    leadGroupsOptions,
    maTeams,
    mappings,
    handleGroupSelect,
    selectedGroup,
    isAdvisorMappingsLoading,
    createLeadSourceGroup,
    isCreateLeadSourceGroupLoading,
    updateLeadSourceGroup,
    isUpdateLeadSourceGroupLoading,
    isUpdateAdvisorMappingsLoading,
  } = useLeadGroups();

  const { isModalOpen, isEditMode, toggleEditMode, openModal, closeModal } =
    useAddEditModal();

  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);

  const collections = useMemo(
    () => ({
      sources: (leadSources ?? []).reduce(
        (acc, source) => set(acc, source, source),
        {}
      ),
      teams: (maTeams ?? []).reduce(
        (acc: Record<string, string>, { maTeamName }: MaTeam) =>
          set(acc, maTeamName, maTeamName),
        {}
      ),
    }),
    [leadSources, maTeams]
  );

  const handleListUpdate = useCallback(
    async ({ list_identifier }, values) => {
      if (list_identifier === "sources") {
        await updateLeadSourceGroup({ sources: [...values] });
      } else {
        await updateLeadSourceGroup({ teamNames: [...values] });
      }
      setIsSaveEnabled(true);
    },
    [updateLeadSourceGroup]
  );

  const handleGroupCreate = useCallback(
    async ({ name }: Record<string, any>) => {
      await createLeadSourceGroup({ groupName: name });
      closeModal();
      setIsSaveEnabled(false);
    },
    [closeModal, createLeadSourceGroup]
  );

  const handleGroupUpdate = useCallback(
    async (values: LeadGroupFormPropsSubmit) => {
      await updateLeadSourceGroup({ ...values });
      toggleEditMode();
      setIsSaveEnabled(false);
    },
    [toggleEditMode, updateLeadSourceGroup]
  );

  const handleCancel = useCallback(() => {
    setIsSaveEnabled(false);
    toggleEditMode();
  }, [toggleEditMode]);

  if (
    !leadGroupsOptions.length ||
    !selectedGroup ||
    isAdvisorMappingsLoading ||
    isCreateLeadSourceGroupLoading ||
    isUpdateLeadSourceGroupLoading ||
    isUpdateAdvisorMappingsLoading ||
    isLeadSourcesLoading
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack spacing={2} width={"100%"}>
      <AddButton onClick={openModal} isHidden={isEditMode} />

      <Selector
        name={"leadGroup"}
        disabled={isEditMode}
        options={leadGroupsOptions}
        onChange={handleGroupSelect}
        initialValue={selectedGroup?.groupId.toString()}
      />
      <ListDataContextProvider
        collections={collections}
        container={{ type: "leadGroup", name: selectedGroup?.groupName ?? "" }}
        allowSaveEmptyList={true}
      >
        <ListBox
          height="250px"
          isEditMode={isEditMode}
          onUpdate={handleListUpdate}
          lists={processLeadGroup(selectedGroup, mappings)}
        />
      </ListDataContextProvider>
      <LeadGroupForm
        onSubmit={handleGroupUpdate}
        onCancel={handleCancel}
        isEditMode={isEditMode}
        isSaveEnabled={isSaveEnabled}
        selectedGroup={selectedGroup}
      />
      {!isEditMode && <Button onClick={toggleEditMode}>Edit</Button>}
      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          title={"New Lead Source Group"}
          inputLabel={"Lead Source Group Name"}
          existingNames={leadGroupsOptions.map(({ label }) => label)}
          onClose={closeModal}
          onSave={handleGroupCreate}
        />
      )}
    </Stack>
  );
};

export default LeadGroupsManager;
