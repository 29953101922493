const routes = {
  loginRedirect: "/login/callback",
  welcome: "/welcome",
  salesTeams: "/sales-teams",
  leadGroups: "/lead-groups",
  pods: "/pods",
  mortgageAdvisors: "/mortgage-advisors",
  operationsUsers: "/operations-users",
  roles: "/roles",
  maCaps: "/ma-caps",
  leadSourceRestriction: "/lead-source-restrictions",
};

export default routes;
