import React, { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Stack } from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ConfirmationDialog,
  useConfirmationDialog,
} from "@sindeo/react-components";
import { ControlledCheckbox } from "@sindeo/react-forms";
import { LeadGroupFormProps } from "forms/LeadGroupForm/LeadGroupForm.interface";
import { LEAD_GROUP_SCHEMA } from "forms/LeadGroupForm/LeadGroupForm.utils";
import { NumberTile } from "ui/NumberTile/NumberTile";

import {
  formatRank,
  formatSpillOverTime,
} from "components/LeadGroupsManager/LeadGroupsManager.utils";

export const LeadGroupForm: React.FC<LeadGroupFormProps> = ({
  selectedGroup,
  onSubmit,
  onCancel,
  isEditMode = false,
  isSaveEnabled = false,
}) => {
  const {
    props: confirmationDialogProps,
    actions: { open: openConfirmationDialog },
  } = useConfirmationDialog({ closeWhenConfirmed: true });

  const defaultValues = useMemo(() => {
    const { rank, spillOverTime, expressAppRequired } = selectedGroup ?? {
      rank: 0,
      spillOverTime: 0,
      expressAppRequired: false,
    };

    return {
      rank,
      spillOverTime,
      expressAppRequired,
    };
  }, [selectedGroup]);

  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(LEAD_GROUP_SCHEMA),
  });

  const {
    handleSubmit: registerHandleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods;

  const isSaveDisabled = useMemo(
    () => (isDirty && !isValid) || (!isDirty && !isSaveEnabled),
    [isDirty, isSaveEnabled, isValid]
  );

  useEffect(() => {
    setValue("rank", defaultValues.rank);
    setValue("spillOverTime", defaultValues.spillOverTime);
    setValue("expressAppRequired", defaultValues.expressAppRequired);
  }, [defaultValues, isEditMode, setValue]);

  const handleCancel = useCallback(async () => {
    if (isSaveDisabled) {
      onCancel();
      return;
    }

    const { isConfirmed } = await openConfirmationDialog({
      title: `Your changes will be lost. Are you sure you want to cancel?`,
    });

    if (isConfirmed) {
      onCancel();
    }
  }, [isSaveDisabled, openConfirmationDialog, onCancel]);

  return (
    <FormProvider {...formMethods}>
      <form id="leadGroupForm" onSubmit={registerHandleSubmit(onSubmit)}>
        <Stack spacing={2} width={"100%"}>
          <Stack direction="row" justifyContent={"space-between"}>
            <NumberTile
              color="#DDF3FF"
              title="Ranking"
              name="rank"
              isEditMode={isEditMode}
              value={formatRank(selectedGroup?.rank)}
            />
            <NumberTile
              color="#F3EDFD"
              title="Spill Over"
              name="spillOverTime"
              isEditMode={isEditMode}
              value={formatSpillOverTime(selectedGroup?.spillOverTime)}
            />
          </Stack>
          <ControlledCheckbox
            css={{ marginBottom: "0" }}
            disabled={!isEditMode}
            label="Express App Start Required"
            labelTypography={{ variant: "displayXS30", fontWeight: "medium" }}
            name="expressAppRequired"
          />
          {isEditMode && (
            <>
              <Button type="submit" disabled={isSaveDisabled}>
                Save
              </Button>
              <Button
                variant={"text"}
                sx={{ m: "16px 0 !important" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <ConfirmationDialog {...confirmationDialogProps} />
            </>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
