export const convertStringArrayToObject = (strings: string[] | null) => {
  if (strings) {
    const list: Record<string, string> = {};
    for (const str of strings) {
      list[str] = str;
    }
    return list;
  }
  return null;
};
