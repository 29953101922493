import {
  Logger,
  LogLevel,
  LogTransport,
  SENSITIVE_FIELDS,
} from "@sindeo/logging";
import appConfig from "config/appConfig";

import pkg from "../../../package.json";

const { APP_VERSION, DATADOG_CLIENT_TOKEN, PLATFORM, MASK_SENSITIVE_LOGS } =
  appConfig;

const logger = new Logger({
  clientToken: DATADOG_CLIENT_TOKEN,
  level: LogLevel.DEBUG,
  sensitiveFields: MASK_SENSITIVE_LOGS ? SENSITIVE_FIELDS : [],
  transport:
    process.env.NODE_ENV === "production"
      ? LogTransport.NETWORK
      : LogTransport.CONSOLE,
  tags: {
    app: "lnd-origination",
    bu: "lnd",
    env: PLATFORM === "qa" ? "sbx" : PLATFORM,
    service: pkg.name,
    version: APP_VERSION,
  },
});

export { logger };
