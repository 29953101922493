import React, { useCallback, useContext, useMemo } from "react";
import { ArrowLeft, LogOut } from "react-feather";
import { Link, Paper, Stack, Typography } from "@achieve/sunbeam";
import {
  ConfirmationDialog,
  useConfirmationDialog,
} from "@sindeo/react-components";
import routes from "constants/routes";
import { GlobalComponentContext } from "contexts/GlobalComponentContext/GlobalComponentContext";
import { noop } from "lodash";

import { PageLayoutProps } from "./PageLayout.interface";

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  children,
  wide,
  noMaxHeight,
}) => {
  const { showExitConfirmation, exitConfirmationMessage } = useContext(
    GlobalComponentContext
  );

  const {
    props: leaveDialogProps,
    actions: { open: openLeaveConfirmationDialog },
  } = useConfirmationDialog({
    closeWhenConfirmed: true,
    isCloseIconShown: false,
    displayStackedActions: true,
  });

  const showClickHandler = useMemo(
    () => showExitConfirmation && exitConfirmationMessage,
    [exitConfirmationMessage, showExitConfirmation]
  );

  const clickHandler = useCallback(
    async (e) => {
      e.preventDefault();
      const { isConfirmed } = await openLeaveConfirmationDialog({
        title: exitConfirmationMessage as string,
      });
      if (isConfirmed) {
        return window.location.replace(routes.welcome);
      }
      return false;
    },
    [exitConfirmationMessage, openLeaveConfirmationDialog]
  );

  return (
    <Stack spacing={3}>
      <section css={{ padding: "24px" }}>
        <Link
          href={routes.welcome}
          underline={"none"}
          onClick={showClickHandler ? clickHandler : noop}
        >
          <ArrowLeft />
          <Typography
            variant="displayS10"
            fontWeight={"bold"}
            sx={{ verticalAlign: "top", paddingLeft: 0.5 }}
          >
            Sales Manager Console
          </Typography>
        </Link>
      </section>
      <main>
        <Stack spacing={5} alignItems={"center"} flexWrap={"wrap"}>
          <Typography
            alignSelf={"center"}
            variant="displayM10"
            fontWeight={"bold"}
          >
            {title}
          </Typography>

          <Paper
            sx={{
              p: 4,
              width: wide ? "880px" : "440px",
              minHeight: "400px",
              maxHeight: noMaxHeight ? null : "800px",
            }}
            elevation={3}
          >
            <Stack spacing={4} alignContent={"center"} flexWrap={"wrap"}>
              {children}

              <Link
                href={routes.welcome}
                underline={"none"}
                mt={4}
                alignSelf={"center"}
                onClick={showClickHandler ? clickHandler : noop}
              >
                <LogOut />
                <Typography
                  variant="displayS10"
                  fontWeight={"bold"}
                  sx={{ verticalAlign: "top", paddingLeft: 0.5 }}
                >
                  Exit
                </Typography>
              </Link>
              <ConfirmationDialog {...leaveDialogProps} />
            </Stack>
          </Paper>
        </Stack>
      </main>
    </Stack>
  );
};

export default PageLayout;
