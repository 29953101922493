import React, { useCallback, useMemo } from "react";
import { Box, Button, CircularProgress, Stack } from "@achieve/sunbeam";
import ListDataContextProvider from "contexts/ListDataContext/ListDataContext";
import { useAddEditModal } from "hooks/useAddEditModal/useAddEditModal";
import { useMortgageAdvisors } from "hooks/useMortgageAdvisors/useMortgageAdvisors";
import { useOperationsPersonnel } from "hooks/useOperationsPersonnel/useOperationsPersonnel";
import { useOperationsTypes } from "hooks/useOperationsTypes/useOperationsTypes";
import { usePods } from "hooks/usePods/usePods";
import { AddButton } from "ui/AddButton/AddButton";
import AddEditModal from "ui/AddEditModal";
import ListBox from "ui/ListBox";
import { SingleList } from "ui/ListBox/ListBox.interface";
import Selector from "ui/Selector";

import {
  buildPodPayload,
  processMortgageAdvisors,
  processOperationsPersonnel,
  processPod,
} from "components/PodsManager/PodsManager.utils";

const PodsManager: React.FC = () => {
  const { operationsTypes, isOperationsTypesLoading } = useOperationsTypes();
  const { mortgageAdvisors, isMortgageAdvisorsLoading } = useMortgageAdvisors();
  const { operationsPersonnel, isOperationsPersonnelLoading } =
    useOperationsPersonnel();

  const {
    pods,
    podsOptions,
    isPodsLoading,
    isCreatePodLoading,
    isUpdatePodLoading,
    createPod,
    updatePod,
    handlePodSelect,
    selectedPod,
  } = usePods();

  const { isModalOpen, isEditMode, toggleEditMode, openModal, closeModal } =
    useAddEditModal();

  const collections = useMemo(
    () => ({
      mortgage_advisors: processMortgageAdvisors(mortgageAdvisors, pods),
      ...processOperationsPersonnel(operationsPersonnel),
    }),
    [mortgageAdvisors, operationsPersonnel, pods]
  );

  const handleAddPod = useCallback(
    async ({ name }) => {
      await createPod({ name });
      closeModal();
    },
    [closeModal, createPod]
  );

  const handleUpdatePod = useCallback(
    async (list: SingleList, values: string[]) => {
      await updatePod(buildPodPayload(selectedPod, list, values));
    },
    [selectedPod, updatePod]
  );

  if (
    isPodsLoading ||
    isCreatePodLoading ||
    isUpdatePodLoading ||
    !podsOptions.length ||
    !selectedPod ||
    isOperationsTypesLoading ||
    isMortgageAdvisorsLoading ||
    isOperationsPersonnelLoading
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack spacing={2} width={"100%"}>
      <AddButton onClick={openModal} isHidden={isEditMode} />
      <Selector
        name={"pod"}
        disabled={isEditMode}
        options={podsOptions}
        onChange={handlePodSelect}
        initialValue={selectedPod?.pod_id}
      />
      <ListDataContextProvider
        collections={collections}
        container={{ type: "pod", name: selectedPod?.name ?? "" }}
        allowSaveEmptyList={true}
      >
        <ListBox
          isEditMode={isEditMode}
          onUpdate={handleUpdatePod}
          lists={processPod(selectedPod, operationsTypes)}
        />
      </ListDataContextProvider>
      <Button onClick={toggleEditMode}>{isEditMode ? "Save" : "Edit"}</Button>
      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          title={"New Pod"}
          inputLabel={"Pod Name"}
          existingNames={podsOptions.map(({ label }) => label)}
          onClose={closeModal}
          onSave={handleAddPod}
        />
      )}
    </Stack>
  );
};

export default PodsManager;
