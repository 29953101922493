import { AchieveTheme } from "@achieve/sunbeam";
import { css } from "@emotion/react";

export const deleteButton = (theme: AchieveTheme) => css`
  border: ${theme.palette.error.main} solid 2px !important;
  color: ${theme.palette.error.main};

  &:hover {
    background-color: ${theme.palette.error.light} !important;
    border: ${theme.palette.error.main} solid 2px !important;
    color: ${theme.palette.error.main} !important;
  }
`;
