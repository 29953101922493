import React, { useCallback } from "react";
import { Box, Button, CircularProgress, Stack } from "@achieve/sunbeam";
import ListDataContextProvider from "contexts/ListDataContext/ListDataContext";
import { useAddEditModal } from "hooks/useAddEditModal/useAddEditModal";
import { useSalesTeams } from "hooks/useSalesTeams/useSalesTeams";
import { AddButton } from "ui/AddButton/AddButton";
import AddEditModal from "ui/AddEditModal";
import ListBox from "ui/ListBox";
import { SingleList } from "ui/ListBox/ListBox.interface";
import Selector from "ui/Selector";

import { processTeam } from "components/SalesTeamsManager/SalesTeamsManager.utils";

const SalesTeamsManager: React.FC = () => {
  const {
    selectedTeam,
    selectorOptions,
    collections,
    handleTeamSelect,
    createSalesTeam,
    updateSalesTeam,
    isSalesTeamsLoading,
  } = useSalesTeams();

  const { isModalOpen, isEditMode, toggleEditMode, openModal, closeModal } =
    useAddEditModal();

  const handleAddNewTeam = useCallback(
    ({ name }) => {
      createSalesTeam({ maTeamName: name });
      closeModal();
    },
    [closeModal, createSalesTeam]
  );

  const handleUpdateTeam = useCallback(
    (_list: SingleList, values: string[]) => {
      updateSalesTeam({
        maTeamName: selectedTeam?.maTeamName,
        nmlsIds: [...values],
      });
    },
    [selectedTeam?.maTeamName, updateSalesTeam]
  );

  if (isSalesTeamsLoading || !selectorOptions.length || !selectedTeam) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack spacing={2} width={"100%"}>
      <AddButton onClick={openModal} isHidden={isEditMode} />

      <Selector
        name={"maTeam"}
        options={selectorOptions}
        disabled={isEditMode}
        initialValue={selectedTeam?.teamId.toString()}
        onChange={handleTeamSelect}
      />

      <ListDataContextProvider
        collections={collections}
        container={{ type: "salesTeam", name: selectedTeam?.maTeamName ?? "" }}
        allowSaveEmptyList={true}
      >
        <ListBox
          isEditMode={isEditMode}
          onUpdate={handleUpdateTeam}
          lists={processTeam(selectedTeam, collections)}
        />
      </ListDataContextProvider>

      <Button onClick={toggleEditMode}>{isEditMode ? "Save" : "Edit"}</Button>

      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          title={"New MA Team"}
          inputLabel={"MA Team Name"}
          existingNames={selectorOptions.map(({ label }) => label)}
          onClose={closeModal}
          onSave={handleAddNewTeam}
        />
      )}
    </Stack>
  );
};

export default SalesTeamsManager;
