import React from "react";
import ReactDOM from "react-dom";
import { CssBaseline, SunbeamThemeProvider } from "@achieve/sunbeam";
import { Loader } from "@sindeo/react-components";

import App from "./components/App/App";

import "styles/global.scss";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader />}>
      <SunbeamThemeProvider>
        <CssBaseline />
        <App />
      </SunbeamThemeProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
