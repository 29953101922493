import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import { ControlledSelectOption } from "@sindeo/react-forms";
import { OperationsType } from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/rest/rest";
import { sortByField } from "utilities/sort";

export const useOperationsTypes = () => {
  const queryKey = ["operations-types"];
  const queryClient = useQueryClient();

  const [selectedType, setSelectedType] = useState<string>("");

  const {
    data: operationsTypes,
    isLoading: isOperationsTypesLoading,
    error: isOperationsTypesError,
  } = useQuery<OperationsType[], string>({
    queryKey,
    queryFn: () => Rest.ops.getOperationsTypes(),
  });

  const {
    mutateAsync: addOperationsType,
    isLoading: isAddOperationsTypeLoading,
  } = useMutation(
    (role: OperationsType) =>
      Rest.ops.addOperationsTypes({
        data: [role],
      }),
    {
      onSuccess: async (data) => {
        if (data?.length) {
          setSelectedType(data[0].operations_type_code);
        }
        await queryClient.invalidateQueries({ queryKey });
      },
      onError: (error) => {
        logger.error("Error while creating an operations type", error);
      },
    }
  );

  const {
    mutateAsync: deleteOperationsType,
    isLoading: isDeleteOperationsTypeLoading,
  } = useMutation((code: string) => Rest.ops.deleteOperationsType(code), {
    onSuccess: async () => {
      setSelectedType("");
      await queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      logger.error("Error while deleting operations type", error);
    },
  });

  const selectorOptions = useMemo(() => {
    const options =
      operationsTypes?.map(
        ({ operations_type_code, type_name }: OperationsType) => ({
          label: type_name,
          value: operations_type_code,
        })
      ) ?? [];
    return sortByField<ControlledSelectOption>(options);
  }, [operationsTypes]);

  const handleRoleSelect = useCallback((type: string) => {
    setSelectedType(type);
  }, []);

  useEffect(() => {
    if (selectorOptions?.length && !selectedType) {
      setSelectedType(selectorOptions[0].value);
    }
  }, [selectedType, selectorOptions]);

  return {
    operationsTypes,
    selectorOptions,
    selectedType,
    handleRoleSelect,
    addOperationsType,
    deleteOperationsType,
    isAddOperationsTypeLoading,
    isDeleteOperationsTypeLoading,
    isOperationsTypesLoading,
    isOperationsTypesError,
  };
};
