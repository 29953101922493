import React, { useCallback, useContext, useState } from "react";
import { Edit3 } from "react-feather";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@achieve/sunbeam";
import { ListDataContext } from "contexts/ListDataContext/ListDataContext";
import { isEmpty } from "lodash";
import ListEditorModal from "ui/ListEditorModal";
import { sortListByName } from "utilities/sort";

import { ListBoxProps } from "./ListBox.interface";

const ListBox: React.FC<ListBoxProps> = ({
  lists,
  onUpdate,
  isEditMode = false,
  height = "350px",
}) => {
  const { onListEdit } = useContext(ListDataContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const handleSave = useCallback(
    (list, values) => {
      onUpdate(list, values);
      closeModal();
    },
    [closeModal, onUpdate]
  );

  const handleEdit = useCallback(
    (list) => {
      onListEdit(list);
      openModal();
    },
    [onListEdit, openModal]
  );

  const isMultiple = lists.length > 1;

  return (
    <Box
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "4px",
        height,
        overflow: "auto",
        padding: "16px",
      }}
    >
      {lists.map((list, index) => {
        const { title, items } = list;

        return (
          <React.Fragment key={title}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              height={33}
            >
              <Typography variant={"displayXS30"} fontWeight={"medium"}>
                {title}(s)
              </Typography>
              {isEditMode && (
                <Button
                  variant={"text"}
                  startIcon={<Edit3 />}
                  size={"small"}
                  data-testid={"edit-list-button"}
                  onClick={() => handleEdit(list)}
                >
                  Edit
                </Button>
              )}
            </Stack>
            {isEmpty(items) ? (
              <Typography variant={"bodyS20"}>--</Typography>
            ) : (
              <List sx={{ listStyleType: "disc", paddingLeft: "24px" }}>
                {sortListByName(items).map(([id, value]) => (
                  <ListItem
                    key={id}
                    sx={{ display: "list-item", padding: "0" }}
                  >
                    <Typography variant={"bodyS20"}>{value}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
            {isMultiple && index < lists.length - 1 && (
              <Divider sx={{ marginY: 2 }} />
            )}
          </React.Fragment>
        );
      })}
      {isModalOpen && (
        <ListEditorModal
          onSave={handleSave}
          onClose={closeModal}
          open={isModalOpen}
        />
      )}
    </Box>
  );
};

export default ListBox;
