import { QueryCache, QueryClient } from "@sindeo/react-data-query";
import { logger } from "services/logger";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      suspense: true, // ??
    },
  },
  queryCache: new QueryCache({
    onError: (error) => logger.error("Something went wrong", error),
  }),
});
