import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { Loader } from "@sindeo/react-components";
import {
  QueryClientProvider,
  ReactQueryDevtools,
} from "@sindeo/react-data-query";
import { Settings } from "@sindeo/rest-services";
import appConfig from "config/appConfig";
import routes from "constants/routes";
import GlobalComponentContextProvider from "contexts/GlobalComponentContext/GlobalComponentContext";
import { oktaAuth } from "services/okta";
import { queryClient } from "services/queryClient";

import ErrorAlert from "components/ErrorAlert";
import Header from "components/Header";
import { LeadGroupsManager } from "components/LeadGroupsManager";
import { LeadSourceRestrictionsManager } from "components/LeadSourceRestrictionsManager";
import { MortgageAdvisorsDailyCapsManager } from "components/MortgageAdvisorsDailyCapsManager";
import { MortgageAdvisorsManager } from "components/MortgageAdvisorsManager";
import { OperationsUsersManager } from "components/OperationsUsersManager";
import { PodsManager } from "components/PodsManager";
import { RolesManager } from "components/RolesManager";
import { SalesTeamsManager } from "components/SalesTeamsManager";
import { RequiredAuth } from "components/SecureRoute/SecureRoute";
import Welcome from "components/Welcome";

function App() {
  const { PLATFORM, BASIC_AUTH_BLS, BASIC_AUTH_LPS } = appConfig;

  useEffect(() => {
    Settings.initConfig({
      PLATFORM,
      BASIC_AUTH: {
        BASIC_AUTH_BLS,
        BASIC_AUTH_LPS,
      },
    });
  }, [BASIC_AUTH_BLS, BASIC_AUTH_LPS, PLATFORM]);

  const restoreOriginalUri = async (
    _oktaAuth: unknown,
    originalUri: string | undefined
  ) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      {/*<LDProvider PLATFORM={PLATFORM} logger={logger}>*/}
      <Header />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <GlobalComponentContextProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path={routes.loginRedirect}
                element={
                  <LoginCallback
                    errorComponent={ErrorAlert}
                    loadingElement={<Loader />}
                  />
                }
              />
              {/* Since okta-react doesn't support the latest react-router-dom yet, */}
              {/* had to use a workaround for SecureRoute, suggested here: */}
              {/* https://github.com/okta/okta-react/issues/178#issuecomment-1150407182 */}
              <Route path={routes.welcome} element={<RequiredAuth />}>
                <Route path="" element={<Welcome />} />
              </Route>
              <Route path={routes.salesTeams} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={<SalesTeamsManager title={"MA Teams"} />}
                />
              </Route>
              <Route path={routes.leadGroups} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={<LeadGroupsManager title={"Lead Source Groups"} />}
                />
              </Route>
              <Route path={routes.pods} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={
                    <PodsManager
                      title={"Mortgage Advisors and Operations Pods"}
                    />
                  }
                />
              </Route>
              <Route path={routes.mortgageAdvisors} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={
                    <MortgageAdvisorsManager title={"Mortgage Advisors"} />
                  }
                />
              </Route>
              <Route path={routes.operationsUsers} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={
                    <OperationsUsersManager title={"Operations Users"} />
                  }
                />
              </Route>

              <Route path={routes.roles} element={<RequiredAuth />}>
                <Route path="" element={<RolesManager title={"Roles"} />} />
              </Route>
              <Route path={routes.maCaps} element={<RequiredAuth />}>
                <Route
                  path=""
                  element={
                    <MortgageAdvisorsDailyCapsManager
                      title={"Mortgage Advisor Caps"}
                      wide={true}
                      noMaxHeight={true}
                    />
                  }
                />
              </Route>
              <Route
                path={routes.leadSourceRestriction}
                element={<RequiredAuth />}
              >
                <Route
                  path={routes.leadSourceRestriction}
                  element={
                    <LeadSourceRestrictionsManager
                      title={"Lead Source Restrictions"}
                    />
                  }
                />
              </Route>

              <Route
                path="/"
                element={<Navigate replace to={routes.welcome} />}
              />
            </Routes>
          </BrowserRouter>
        </GlobalComponentContextProvider>
      </Security>
      {/*</LDProvider>*/}
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  );
}

export default App;
