import React from "react";
import { Stack, Typography } from "@achieve/sunbeam";
import { ControlledInput } from "@sindeo/react-forms";
import { NumberTileProps } from "ui/NumberTile/NumberTile.interface";
import { numberInput } from "ui/NumberTile/NumberTile.styles";

export const NumberTile: React.FC<NumberTileProps> = ({
  color,
  title,
  name,
  value,
  isEditMode,
}) => {
  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: color,
        height: "110px",
        borderRadius: "8px",
        padding: isEditMode ? "8px 24px" : "16px 24px",
        width: "170px",
      }}
    >
      <Typography variant={"displayXS30"} fontWeight={"medium"}>
        {title}
      </Typography>
      {isEditMode ? (
        <ControlledInput
          label=""
          placeholder={title}
          name={name}
          type="number"
          css={numberInput}
        />
      ) : (
        <Typography variant={"bodyS30"}>{value}</Typography>
      )}
    </Stack>
  );
};
