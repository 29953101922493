import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ControlledSelect as Select } from "@sindeo/react-forms";
import { SelectorProps } from "ui/Selector/Selector.interface";

const Selector: React.FC<SelectorProps> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const defaultValues = useMemo(
    () => ({
      [props.name]: initialValue ?? "",
    }),
    [props.name, initialValue]
  );

  const formMethods = useForm({ defaultValues });
  const { watch } = formMethods;
  const value = watch(props.name);

  useEffect(() => {
    if (value !== initialValue) {
      onChange(value);
    }
  }, [onChange, initialValue, value]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <Select {...props} defaultHelperText={""} />
      </form>
    </FormProvider>
  );
};
export default Selector;
