import { useQuery } from "@sindeo/react-data-query";
import { GetLeadSourcesResponse, LeadSource } from "@sindeo/rest-services";
import { Rest } from "services/rest/rest";

const PARTNER_LEAD_SOURCES_COUNT = 9999;

export const useLeadSources = () => {
  const queryKey = ["lead-sources"];

  const {
    data: leadSources,
    isLoading: isLeadSourcesLoading,
    error: isLeadSourcesError,
  } = useQuery<GetLeadSourcesResponse, unknown, string[]>({
    queryKey,
    queryFn: async () => {
      return Rest.pls.getLeadSources({ size: PARTNER_LEAD_SOURCES_COUNT });
    },
    select: (data: GetLeadSourcesResponse): string[] => {
      return data.content.map((source: LeadSource) => {
        return source.source;
      });
    },
  });

  return {
    leadSources,
    isLeadSourcesLoading,
    isLeadSourcesError,
  };
};
