import React, { useCallback } from "react";
import { Box, CircularProgress, Stack, Typography } from "@achieve/sunbeam";
import {
  ConfirmationDialog,
  useConfirmationDialog,
} from "@sindeo/react-components";
import { OperationsTypeForm } from "forms/OperationsTypeForm/OperationsTypeForm";
import { useAddEditModal } from "hooks/useAddEditModal/useAddEditModal";
import { useOperationsTypes } from "hooks/useOperationsTypes/useOperationsTypes";
import { AddButton } from "ui/AddButton/AddButton";
import AddEditModal from "ui/AddEditModal";
import { DeleteButton } from "ui/DeleteButton/DeleteButton";
import Selector from "ui/Selector";

import { ROLE_SCHEMA } from "components/RolesManager/RolesManager.utils";

const RolesManager: React.FC = () => {
  const {
    isOperationsTypesLoading,
    selectorOptions,
    handleRoleSelect,
    selectedType,
    addOperationsType,
    deleteOperationsType,
    isAddOperationsTypeLoading,
    isDeleteOperationsTypeLoading,
  } = useOperationsTypes();

  const { isModalOpen, openModal, closeModal } = useAddEditModal();

  const {
    props: confirmationDialogProps,
    actions: { open: openConfirmationDialog },
  } = useConfirmationDialog({ closeWhenConfirmed: true });

  const handleAddRole = useCallback(
    async ({ name, operations_type_code }) => {
      await addOperationsType({ type_name: name, operations_type_code });
      closeModal();
    },
    [addOperationsType, closeModal]
  );

  const handleDeleteRole = useCallback(async () => {
    const { isConfirmed } = await openConfirmationDialog({
      title: `Are you sure you want to delete this role?`,
    });

    if (isConfirmed) {
      await deleteOperationsType(selectedType);
    }
  }, [deleteOperationsType, openConfirmationDialog, selectedType]);

  if (
    isOperationsTypesLoading ||
    !selectorOptions?.length ||
    !selectedType ||
    isAddOperationsTypeLoading ||
    isDeleteOperationsTypeLoading
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Stack
      spacing={2}
      width={"100%"}
      height={"30vh"}
      justifyContent={"space-between"}
    >
      <Stack spacing={2}>
        <Box height={30} sx={{ alignSelf: "end" }}>
          <AddButton onClick={openModal} />
        </Box>

        <Selector
          name={"operationsType"}
          options={selectorOptions}
          onChange={handleRoleSelect}
          initialValue={selectedType}
        />
        <Stack direction="row" spacing={1} paddingX={2}>
          <Typography variant={"displayXS30"} fontWeight={"medium"}>
            Role Abbreviation:
          </Typography>
          <Typography variant={"displayXS30"}>{selectedType}</Typography>
        </Stack>
      </Stack>

      <DeleteButton onClick={handleDeleteRole} />

      {isModalOpen && (
        <AddEditModal
          open={isModalOpen}
          title={"Add New Role"}
          onClose={closeModal}
          onSave={handleAddRole}
          defaultValues={{
            name: "",
            operations_type_code: "",
          }}
          schema={ROLE_SCHEMA(selectorOptions)}
        >
          <OperationsTypeForm />
        </AddEditModal>
      )}

      <ConfirmationDialog {...confirmationDialogProps} />
    </Stack>
  );
};
export default RolesManager;
