import React, { useCallback, useContext, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@achieve/sunbeam";
import { ConfirmationDialog } from "@sindeo/react-components";
import { ContainerMap } from "constants/mappings";
import { ListDataContext } from "contexts/ListDataContext/ListDataContext";
import { useListEditor } from "hooks/useListEditor/useListEditor";
import ListEditor from "ui/ListEditor/ListEditor";

import { ListEditorModalProps } from "./ListEditorModal.interface";

const ListEditorModal: React.FC<ListEditorModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const { allowSaveEmptyList, listInEdit, currentChoiceList, container } =
    useContext(ListDataContext);

  const listEditorMethods = useListEditor({
    choiceList: currentChoiceList,
    chosenList: listInEdit,
    allowSaveEmptyList,
  });

  const { isSaveDisabled, selection, validateSave, confirmationDialogProps } =
    listEditorMethods;
  const { title } = listInEdit;

  const dialogTitle = useMemo(
    () => `Edit ${title}s ${ContainerMap[container.type]} ${container.name}`,
    [container.name, container.type, title]
  );

  const handleSave = useCallback(async () => {
    if (await validateSave()) {
      onSave(listInEdit, [...selection]);
    }
  }, [listInEdit, onSave, selection, validateSave]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ alignSelf: "start" }}>{dialogTitle}</DialogTitle>
        <DialogContent sx={{ border: "none !important" }}>
          <ListEditor {...listEditorMethods} />
        </DialogContent>
        <DialogActions
          sx={{ flexDirection: "column", alignItems: "stretch !important" }}
        >
          <Button onClick={handleSave} disabled={isSaveDisabled}>
            Save
          </Button>
          <Button
            variant={"text"}
            sx={{ m: "16px 0 !important" }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog {...confirmationDialogProps} />
    </>
  );
};

export default ListEditorModal;
